* {
  box-sizing: border-box;
}

body {
  background-color: rgb(244, 244, 249);
  color: rgb(65, 65, 65);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

a {
  color: var(--aa-primary-color);
  text-decoration: none;
}

.header {
  background: rgb(252 252 255 / 92%);
  box-shadow: 0 0 0 1px rgba(35, 38, 59, 0.05),
    0 1px 3px 0 rgba(35, 38, 59, 0.15);
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.header-wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr;
}

.header-nav {
  font-weight: 500;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.5rem;
  width: 100%;
}

.container {
  margin-top: 3.5rem;
  padding: 1.5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 3fr;
}

.container img {
  max-width: 100%;
}

.banner {
  border-radius: 3px;
  margin-top: 1rem;
}

/* Autocomplete */

.aa-Panel {
  position: fixed;
}

/* InstantSearch */

.ais-Hits {
  margin-top: 1rem;
}

.ais-Hits-list {
  display: flex;
  flex-direction: column;
}

.ais-Hits-item {
  padding: 1rem !important;
}

.hit {
  align-items: flex-start;
  gap: 1rem;
}

.hit h1 {
  font-size: 1rem;
}

.hit p {
  font-size: 0.8rem;
  opacity: 0.8;
}

.hit-image {
  align-items: flex-start;
  display: flex;
  height: 100px;
  justify-content: flex-start;
  width: 100px;
}

.hit-image img {
  width: 100%;
  max-height: 100%;
}

.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent
  > div:first-of-type
  .ais-HierarchicalMenu-label {
  font-weight: bold;
}

.ais-HierarchicalMenu-item--selected:not(.ais-HierarchicalMenu-item--parent)
  .ais-HierarchicalMenu-label {
  font-weight: bold;
}

.ais-Pagination {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

a:hover{
  color: #C45322;
  transition: all 250ms ease-in-out;
}

a:hover strong {
  color: #C45322;
  transition: all 250ms ease-in-out;
}

.ais-HierarchicalMenu-link--selected, .ais-Panel-header {
  color: #C45322 !important;
}

.ais-Highlight-highlighted {
  background-color: rgba(230, 233, 66, 0.3) !important;
  color: #3c3c3c !important;
}

.ais-Hits-item {
  flex-direction: column;
  align-items: flex-start !important;
}
.hit_post-type {
  margin-bottom: 20px;
  color: #C45322;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
}